<template>
  <div class="lxp-dashboard__recommended-courses">
    <b-row class="h-100" v-if="isLoading === true">
      <b-col
        cols="12"
        md="4"
        lg="4"
        class="mb-3"
        v-for="index in 3"
        :key="index"
      >
        <shimmer
          variant="landing-course-card"
          style="width: 100%; height: 100%;"
        />
      </b-col>
    </b-row>
    <div class="lx-course-card__scroll">
      <div v-for="(course, index) in courses" :key="index">
        <div class="lx-course-card__wrapper">
          <CourseCard
            :courseImage="
              course.image_url
                ? course.image_url
                : require('@/assets/images/illustration/course-illustration.svg')
            "
            :org="course.org"
            :courseTitle="course.title"
            :pacing="course.pacing"
            :courseLanguage="course.language"
            @click="$emit('click', course)"
            @orgClick="onOrgClick(course)"
            :course="course"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import redirectToLearningSpace from "@/core/mixins/redirectToLearningSpace";

export default {
  mixins: [redirectToLearningSpace],
  props: {
    isLoading: {
      type: Boolean,
      default: true
    },
    courses: {
      type: Array,
      required: true
    }
  },
  methods: {
    onOrgClick(c) {
      this.navigateToLearningSpace(c.org);
    }
  }
};
</script>
<style lang="scss">
.lxp-dashboard__recommended-courses {
  .lx-course-card__scroll {
    margin: 0;
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(clamp(33.33% - 16px, 210px, 100%), 1fr)
    );
    gap: 24px;
  }
  @media screen and (max-width: 1224px) {
    .lx-course-card__scroll {
      display: flex;
      overflow-y: auto;
      padding: 4px;
    }
  }
  @media screen and (max-width: 480px) {
    .lx-course-card__scroll {
      padding: 0px;
    }
    .lx-course-card-mobile-view {
      width: 315px;
    }
  }

  .lx-course-card__wrapper {
    height: 100%;
    display: flex;
    border-radius: 16px;
  }

  .lx-course-card {
    height: 100%;
    flex: 1;
  }

  .lx-course-card:first-child {
    flex: 1.5;
  }

  .v-tour--active {
    #dashboardRecommendations {
      &.v-tour__target--highlighted {
        height: 350px !important;
        border-radius: 10px;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .lx-course-card__scroll {
      padding: 0;
    }
  }
}
</style>
