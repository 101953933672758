<template>
  <div class="h-100" id="qa-dashboard-view">
    <LayoutManager>
      <div
        class="lxp-dashboard"
        :class="{ 'show-complete-profile': showCompleteProfile }"
      >
        <div class="pb-4">
          <user-alerts v-if="getAuthProfile.is_mfa_enabled === false" />
          <dashboard-alerts
            v-if="!isClLoading"
            @startLearning="startLearning"
          ></dashboard-alerts>
          <b-row
            no-gutters
            class="lxp-dashboard__my-programmes__top h-100"
            id="dashboardMyProgrammes"
            v-if="
              enrolledCourses.length !== 0 &&
                enrolledCourses.results.length != 0
            "
          >
            <b-col align-self="stretch">
              <div class="lxp-dashboard__my-programmes">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <DashboardHeading
                      iconFileName="my-learning-heading-blue-icon.svg"
                      :count="enrolledCourses.count ? enrolledCourses.count : 0"
                    >
                      {{ $t("my_learning.heading") }}
                    </DashboardHeading>
                  </div>
                  <div
                    class="lxp-dashboard__see_all"
                    v-if="myLearningCoursesVisible < enrolledCourses.count"
                  >
                    <span @click="$router.push({ path: '/my-learning' })">{{
                      $t("general.see_all")
                    }}</span>
                  </div>
                </div>

                <MyProgrammes
                  :isLoading="isClLoading"
                  :courses="visibleMyLearningCourses"
                  @click="onCard"
                  @startLearning="startLearning"
                  @onMyCardClick="onMyCard"
                  @unenrollModalData="unenrollModalData"
                  :allEnrolledCourses="enrolledCourses.results"
                />
              </div>
            </b-col>
          </b-row>
          <b-row v-if="!isRcLoading">
            <b-col align-self="stretch">
              <div
                class="lxp-dashboard__recommendations"
                id="dashboardRecommendations"
              >
                <DashboardHeading
                  iconFileName="recommendations-heading-yellow-icon.svg"
                >
                  {{ $t("my_learning.tab.recommendations") }}
                </DashboardHeading>
                <RecommendedCourses
                  :isLoading="isRcLoading"
                  :courses="recommendedCourses"
                  @click="onCard"
                />
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col align-self="stretch">
              <div
                class="lxp-dashboard__discover-learning"
                id="dashboardDiscover"
              >
                <div class="d-flex justify-content-between">
                  <div>
                    <DashboardHeading
                      iconFileName="discover-learning-heading-blue-icon.svg"
                    >
                      {{ $t("dashboard.discover_learning") }}
                    </DashboardHeading>
                  </div>
                  <div class="lxp-dashboard__view-all">
                    <span
                      @click="$router.push({ path: '/course-discovery' })"
                      >{{ $t("general.view_all") }}</span
                    >
                  </div>
                </div>
                <DiscoverCourses
                  :isLoading="isDlLoading"
                  :course="discoverCourse"
                  @click="navToCourseDetail"
                  :discouryCourses="[]"
                />
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </LayoutManager>
    <unenroll-modal
      @unenrollSuccessModalClose="unenrollSuccessModalClose"
      :courseId="selectedCourseId"
    ></unenroll-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import openFeedbackForm from "@/core/mixins/openFeedbackForm.js";
import redirectToCourseDetail from "@/core/mixins/redirectToCourseDetail";

import UnenrollModal from "@/components/CourseDetail/Modals/UnenrollModal.vue";
import LayoutManager from "@/components/Layout/LayoutManager";

// child components
import MyProgrammes from "@/components/Dashboard/MyProgrammes.vue";
import RecommendedCourses from "@/components/Dashboard/RecommendedCourses.vue";
import DiscoverCourses from "@/components/Dashboard/DiscoverCourses.vue";
import UserAlerts from "@/components/UserAlerts.vue";
import DashboardAlerts from "@/components/Dashboard/DashboardAlerts.vue";
import getCoursesMixin from "@/components/SearchCourse/mixins/getCourses";

export default {
  components: {
    LayoutManager,
    MyProgrammes,
    RecommendedCourses,
    DiscoverCourses,
    UserAlerts,
    UnenrollModal,
    DashboardAlerts
  },
  mixins: [getCoursesMixin, openFeedbackForm, redirectToCourseDetail],
  data() {
    return {
      isNew: true,
      isClLoading: true,
      isRcLoading: true,
      isDlLoading: true,
      discoverCourse: {},
      modalShow: true,
      recommendedCourses: [],
      myLearningCoursesVisible: 4,
      selectedCourseId: ""
    };
  },
  mounted() {
    if (this.isUserFluidLayoutLoaded()) {
      this.getCourses();
      this._getAllCourses();
    }
  },
  computed: {
    ...mapGetters([
      "allCourses",
      "language",
      "showCompleteProfile",
      "getAuthProfile",
      "getSelectedCourse",
      "enrolledCourses"
    ]),
    visibleMyLearningCourses() {
      return this.enrolledCourses.results.slice(
        0,
        this.myLearningCoursesVisible
      );
    },
    courseCount() {
      return this.visibleMyLearningCourses.length > 3
        ? 4
        : this.visibleMyLearningCourses.length;
    }
  },
  methods: {
    unenrollModalData(data) {
      this.$bvModal.show(data.modalId);
      this.selectedCourseId = data.courseId;
    },
    async getRandomCourse(count) {
      let randomCourses = [];
      await this.$store.dispatch("getRandomCourses", count).then(data => {
        randomCourses = data;
      });
      return randomCourses;
    },
    getRecommendedCourse(count) {
      this.$store.dispatch("getRecommendedCourses", count).then(data => {
        this.isRcLoading = false;
        this.recommendedCourses = data;
      });
    },
    onCard(c) {
      this.$router.push({
        name: "Courses Detail",
        params: { id: c.id },
        query: { from: "home-courses", source: c.source }
      });
    },
    onMyCard(c) {
      this.$router.push({
        name: "Self Paced",
        params: { id: c.id },
        query: { from: "home-courses" }
      });
    },
    async getCourses() {
      this.getRecommendedCourse(3);
      let course = await this.getRandomCourse(1);
      this.discoverCourse = course[0];
      this.isDlLoading = false;
      if (this.enrolledCourses.length === 0) {
        this.$store.dispatch("getEnrolledCourses").then(() => {
          this.isClLoading = false;
        });
      } else {
        this.isClLoading = false;
      }
    },
    startLearning() {
      this.$router.push({ name: "course-discovery" }).catch(() => {});
    },
    unenrollSuccessModalClose() {
      this.getCourses();
      this._getAllCourses();
    }
  }
};
</script>
<style lang="scss">
.lxp-dashboard {
  width: 100%;
  height: 100%;
  padding: 30px 30px 0;

  .dashboard-row-height {
    height: calc(100% - 250px);
  }
  .lxp-dashboard__view-all {
    @include dashboard-label;
    color: $brand-primary-700;
    cursor: pointer;
    align-self: center;
  }
  .lxp-dashboard__see_all {
    cursor: pointer;
    align-self: center;
    height: 24px;
    @include dashboard-label;
    color: $brand-secondary1-400;
  }
  .lxp-dashboard__recommendations {
    height: 100%;
    .lxp-dashboard__recommended-courses {
      height: calc(100% - 50px);
      margin: 20px 0px 0px 0px;
      text-align: left;
    }
  }

  .lxp-dashboard__discover-learning {
    height: 100%;

    .lxp-dashboard__discover-courses {
      height: calc(100% - 80px);
      margin: 20px 0px 30px 0px;
      text-align: left;
      .d-block {
        padding: 0;
      }
    }
    .cd-main__loader--wrapper {
      .course-card {
        width: 240px !important;
      }
    }
  }

  .lxp-dashboard__my-programmes-wrapper {
    height: 100%;

    .lxp-dashboard__my-programmes__top {
      height: calc(100% - 120px);
    }

    .lxp-dashboard__my-programmes {
      padding: 0 23px;
      height: 100%;
    }

    .lxp-dashboard__my-courses {
      text-align: left;
      display: flex;
      justify-content: center;
    }
  }

  &.show-complete-profile {
    .lxp-dashboard__recommendations {
      height: 90%;
    }
  }
  .welcome-back__msg-alert {
    .alert {
      padding: 16px;
      background-color: $brand-primary-100;
      border: none !important;
    }
    .btn-outline-primary {
      color: $brand-primary-400;
      border-color: $brand-primary-400;

      &:hover,
      &:focus {
        background-color: transparent;
      }
    }
    .username {
      @include subtitle-large;
      color: $brand-primary-400;
    }
    .welcome-back__msg-alert--title {
      @include subtitle-large;
    }
    .welcome-back__msg-alert--subtitle {
      @include body-regular;
      margin-bottom: 0;
    }
    .welcome-back__enroll-today {
      padding-left: 16px;
      align-self: center;
    }
  }
  .welcome-back__msg-alert-mobile {
    .alert {
      background-color: $brand-primary-100;
      border: none !important;
      text-align: center;
    }
    .btn-outline-primary {
      color: $brand-primary-400;
      border-color: $brand-primary-400;

      &:hover,
      &:focus {
        background-color: transparent;
      }
    }
    .content {
      @include body-medium;
    }
  }
}
#rasaWebchatPro {
  display: block !important;
}
#dashboardMyProgrammes {
  padding: 0px 20px;
}
#dashboardRecommendations,
#dashboardDiscover {
  padding: 12px 20px;
}
@media screen and (max-width: 480px) {
  .welcome-back__msg-alert-mobile {
    display: block !important;
  }
  .welcome-back-msg-alert {
    display: none;
  }
  .align-self-stretch {
    padding: 0;
  }
  .lxp-dashboard__discover-courses {
    .ld-sec__tabs {
      .pagination__wrapper {
        .course-card-column {
          padding-right: 10px;
          padding-left: 0px;
        }
      }
    }
  }
  .lxp-dashboard__recommended-courses {
    .course-card-column {
      padding-right: 0px;
    }
  }
  .ac__load-more {
    top: -25px !important;
  }
  .lxp-dashboard__discover-learning {
    .d-block {
      padding: 0;
    }
  }
  .lxp-dashboard {
    padding: 0px 16px;
  }
  #dashboardRecommendations,
  #dashboardDiscover {
    padding: 12px;
  }
  .v-tour--active {
    #dashboardRecommendations {
      &.v-tour__target--highlighted {
        height: 200px;
        overflow: hidden;
      }
    }
  }
  #dashboardMyProgrammes {
    padding: 24px;
  }
}
[dir="rtl"] {
  .lxp-dashboard {
    .welcome-back__msg-alert {
      .alert-info {
        > div {
          justify-content: space-between !important;
        }
      }
      .welcome-back__enroll-today {
        padding-left: 0;
        padding-right: 16px;
      }
    }
  }
  @media screen and (max-width: 480px) {
    .lxp-dashboard__recommended-courses {
      .course-card-column {
        padding-right: 10px;
        padding-left: 0px;
      }
    }
    .lxp-dashboard__discover-courses {
      .ld-sec__tabs {
        .pagination__wrapper {
          .course-card-column {
            padding-right: 10px;
            padding-left: 0px;
          }
        }
      }
    }
    .lxp-dashboard__discover-learning {
      .d-block {
        padding: 0 0 0 15px;
      }
    }
  }
}
</style>
